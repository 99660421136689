import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"
import HomeIcon from "../components/Icons/HomeIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import Image from "../components/image"

const Nodata = styled.div`
	text-align: center;
	padding: 120px 0;
	${BreakpointUp.lg`
		padding: 220px 0 120px 0;
	`}
`
const NodateContent = styled.div`
	max-width:920px;
	width:100%;
	margin:0 auto;
`
const NodateFigure = styled.figure`
	margin-bottom:30px;
	.gatsby-image-wrapper{
		max-width:235px;
		width:100%;
		margin:0 auto;
	}
`
const NodateText = styled.div`
	& h1 {
		margin-bottom:20px;
	}
	& p {
		margin-bottom:25px;		
	}
`
const ButtonToolbar = styled.div`
	text-align:center;
`
const Thanks = ({ data }) => (
  <Layout>
    <SEO title="thanks" />
    <Nodata>
	    <div className="container">
			<NodateContent>
				<NodateFigure><Image name="thanks-icon.jpg" alt="thanks"  /></NodateFigure>
			</NodateContent>		    
			<NodateText>
				<h1>Thank you for connecting with us </h1>
				<p> 
					Thank you for your interest in Mr. Carports with your metal building needs! We'll certainly get back to you with more details.
				</p>
				<ButtonToolbar>
					<Link to="/"><DefaultButton icon={<HomeIcon />} text="Go To Homepage"></DefaultButton></Link>
				</ButtonToolbar>
			</NodateText>
	  	</div>
	  </Nodata>
  </Layout>
)

export default Thanks
